<template>
    <div>
        <Products/>
    </div>
</template>

<script>
import Products from '@/components/Products.vue';

export default {
    name: 'home-view',
    components: {
        Products
    }
}
</script>