<template>
    <div class="flex  justify-center border-0">
      <div class="w-full align-middle fixed -bottom-0.5 border-0 text-left bg-gray-800 opacity-9`0 py-4 shadow-xl flex justify-between rounded-t-sm" style="border-bottom-width: 1.5px;">
        <div class="flex-md items-center space-x-2 text-center w-full">
          <span class="text-xs text-white font-mono cursor-pointer ">Copyright @ {{new Date().getFullYear()}} | Fourth General Suppliers</span>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: 'footer-component',
        props: {
        },
        methods: {
        }
  }
</script>

<style lang="css" scoped>
</style>