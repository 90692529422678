<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.text-title {
  font-size: 0.78rem;
}

.carousel-container {
    width: 300px;
    overflow: hidden;
}

.carousel-item {
    width: 100%;
    float: left;
    animation: slide 42s ease-in-out infinite;
}

@keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(-300%); }
}

.animate{
  animation: anim4 3s ease-in-out infinite;
}
        @keyframes anim4{
            0%{ transform: translateX(10%);}
            /* 50%{ transform: translateX(200);} */
            100% { transform: translateX(2%);}
        }

.text-size {
  font-size: 0.80rem;
}

.text-size-md {
  font-size: 0.86rem;
}

.dots-pattern{
  /* background-color: #f5f5f7; */
  background-image: radial-gradient(circle at 10px 10px, black 1px, transparent 0);
  background-size: 40px 40px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
