<template>
    <div>
        <Home/>
    </div>
</template>

<script>
import Home from '@/components/Home.vue';

export default {
    name: 'home-view',
    components: {
        Home
    }
}
</script>