<template>
  <div class="">
    <!-- the navbar -->
    <Navbar class=" sticky top-0"/>
    <!-- company description -->
    <div class=" bg-blue-400">
      <!-- animation -->
      <Animation/>
      <div class="py-32 space-y-5">
        <p class=" text-center md:text-5xl text-4xl text-white font-bold">
          Our Company Goal
        </p>
        <p class=" text-center md:text-md text-sm px-4 text-white">
          We are a company that believes in producing while conserving our resources for the future. A perfect choice for the consumer.
        </p>
      </div>
    </div>
    <!-- company Focus -->
    <div class="py-6">
      <!-- the focus areas -->
      <div>
        <span class=" font-bold">Our Focus Areas</span>
      </div>
      <!-- the cards for the focus areas -->
       <div class="relative grid grid-cols-1 md:grid-cols-4 mb-4 gap-6 mt-6 px-6 bg-50">
        <div v-for="(data, i) in products" :key="i" class="w-full shadow-sm hover:shadow-md border col-span-1 cursor-pointer">
          <div class="">
            <div class=" bg-gray-100 text-left px-3 py-3 justify-between flex">
              <span class=" text-xs font-bold">{{ data.name }}</span>
              <span class=" text-xs font-medium text-red-800"></span>
            </div>
            <div class=" text-left px-3 py-1.5 mb-1.5">
              <span class=" text-xs font-medum">{{ data.description }}</span>
            </div>
          </div>
        </div>
       </div>
    </div>
    <!-- just the pattern -->
    <div class=" dots-pattern h-16 mb-32">
    </div>
    <!-- the footer -->
    <Footer/>
  </div>
</template>

<script>
import Animation from './Animation.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';

export default {
  name: 'HelloWorld',
  components: {
    Navbar,
    Animation,
    Footer
  },
  mounted: () => {
    localStorage.setItem('nav-select', 0)
  },
  props: {
    msg: String
  },
  data: () => {
    return {
      products: [
        {
          name: 'Groundnuts',
          description: 'Our Groundnuts/Peanuts products, ranging from raw, shelled, and in-shell groundnuts to pure groundnut oil, are sourced directly from our fields and processed to perfection. We cater to a wide range of customers, including food manufacturers, retailers, and wholesalers, ensuring a reliable supply of premium groundnut products.'
        },
        {
          name: 'Soya',
          description: 'From our farms to your table, we prioritize sustainable practices and stringent quality control. Our soya products, ranging from raw soybeans to processed soybean oil and meal, are sourced directly from our fields and processed to perfection. We cater to a wide range of customers, including food manufacturers, livestock feed producers, and industrial users, ensuring a reliable supply of premium soybean products.'
        },
        {
          name: 'Goat Farming',
          description: 'We raise our goats in a nurturing environment, ensuring they receive the highest quality feed and care. Our commitment to animal welfare and sustainable farming practices results in healthy, flavorful products. Whether you`re seeking tender, lean goat meat or nutritious, creamy goat milk, we offer a range of options to satisfy your taste buds and dietary needs.'
        },
        {
          name: 'Cattle Farming',
          description: 'We have a dedicated cattle farming operation committed to providing premium beef and dairy products. We raise our cattle in a nurturing environment, ensuring they receive the highest quality feed and care. Our commitment to animal welfare and sustainable farming practices results in healthy, flavorful products. Whether you`re seeking tender, juicy beef cuts or nutritious, creamy milk, we offer a range of options to satisfy your taste buds and dietary needs.'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
