<template>
  <div class="">
    <!-- the navbar -->
    <Navbar class=" sticky top-0"/>
    <!-- company description -->
    <div class=" bg-blue-400">
      <!-- animation -->
       <Animation/>
      <div class="py-32 space-y-5">
        <p class=" text-center md:text-5xl text-4xl text-white font-bold">
          Contact Details
        </p>
        <p class=" text-center md:text-md text-sm text-white">
          For further enquiries contact us via email, phone or  the postal service, using the contact details below.
        </p>
      </div>
    </div>
    <!-- company Focus -->
    <div class="py-6">
      <!-- the focus areas -->
      <!-- <div>
        <span class=" font-bold">Contact Us</span>
      </div> -->
      <!-- the cards for the focus areas -->
       <div class="relative grid grid-cols-1 md:grid-cols-4 mb-4 gap-6 mt-6 px-6 bg-white">
        <!-- <div class="w-full shadow-sm hover:shadow-md border col-span-1 cursor-pointer p-6 space-y-4"> -->

          <div class="flex space-x-2">
            <div class=" text-left mb-1.5 bg-blue-500 p-6 shadow-sm rounded-sm flex items-center">
              <svg class="h-8 w-8 fill-white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                <path d="m9.671,21.562c.518.647.413,1.591-.234,2.108-.276.222-.607.329-.936.329-.44,0-.876-.192-1.172-.562l-4-5C1.63,16.17,0,13.729,0,10.567,0,7.737,1.091,5.082,3.072,3.091,5.056,1.098,7.694,0,10.5,0s5.443,1.098,7.427,3.09c2.085,2.097,3.201,4.995,3.062,7.954-.01.204-.025.408-.046.61-.085.824-.818,1.417-1.646,1.338-.824-.085-1.423-.822-1.338-1.646.015-.147.027-.295.034-.442.1-2.12-.699-4.197-2.191-5.697-1.416-1.423-3.298-2.206-5.3-2.206s-3.885.783-5.301,2.207c-1.418,1.425-2.199,3.329-2.199,5.36,0,2.241,1.278,4.133,2.701,6.033l3.971,4.962Zm3.951-6.524l3.671,3.67c.378.377,1.036.377,1.414,0l3.67-3.67c-.122-.023-.248-.038-.377-.038h-8c-.129,0-.255.015-.378.038Zm10.263,1.32l-3.764,3.764c-.585.585-1.353.877-2.121.877s-1.536-.292-2.121-.877l-3.764-3.764c-.069.202-.115.416-.115.641v5c0,1.105.895,2,2,2h8c1.105,0,2-.895,2-2v-5c0-.226-.046-.439-.115-.642Zm-13.385-1.358c-2.481,0-4.5-2.019-4.5-4.5s2.019-4.5,4.5-4.5,4.5,2.019,4.5,4.5-2.019,4.5-4.5,4.5Zm1.5-4.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5,1.5.673,1.5,1.5,1.5,1.5-.673,1.5-1.5Z"/>
              </svg>
            </div>
            <div class="flex-1 text-left text-size text-gray-500">
              <p class="font-bold">Post Address</p>
              <p>Fourth General Suppliers,</p>
              <p>P.O. Box 30850,</p>
              <p>Lilongwe 3.</p>
            </div>
          </div>


          <div class="flex space-x-2">
            <div class=" text-left mb-1.5 bg-orange-400 p-6 shadow-sm rounded-sm flex items-center">
              <svg class="h-8 w-8 fill-white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17.5,24C9.351,24.229-5.075,9.787,1.862,1.938,1.887,1.914,2.954.984,2.954.984a3.583,3.583,0,0,1,4.908.041l2.152,2.318a3.522,3.522,0,0,1-.039,4.91l-.388.394a11.6,11.6,0,0,0,5.754,5.778l.411-.4a3.585,3.585,0,0,1,4.945,0L22.934,16.1a3.515,3.515,0,0,1,.082,4.946s-.93,1.066-.954,1.091A6.39,6.39,0,0,1,17.5,24ZM3.95,4.1C-.909,10.118,14.644,24.773,19.905,20.05c0,0,.924-1.059.949-1.083a.5.5,0,0,0,0-.707l-2.238-2.074c-.3-.25-.469-.253-.747-.04l-1.136,1.118a1.5,1.5,0,0,1-1.587.332A14.852,14.852,0,0,1,6.414,8.877a1.5,1.5,0,0,1,.323-1.61L7.845,6.141a.485.485,0,0,0,.009-.717L5.7,3.106a.487.487,0,0,0-.667.04C5.009,3.171,3.95,4.1,3.95,4.1ZM16.808,15.086h0ZM24,9.5A9.511,9.511,0,0,0,14.5,0a1.5,1.5,0,0,0,0,3A6.508,6.508,0,0,1,21,9.5a1.5,1.5,0,0,0,3,0Zm-5,0A4.505,4.505,0,0,0,14.5,5a1.5,1.5,0,0,0,0,3A1.5,1.5,0,0,1,16,9.5a1.5,1.5,0,0,0,3,0Z"/></svg>
            </div>
            <div class="flex-1 text-left text-size text-gray-500">
              <p class="font-bold">Phone</p>
              <p>+265995887474</p>
              <p>+265884280477</p>
              <p></p>
            </div>
          </div>


          <div class="flex space-x-2">
            <div class=" text-left mb-1.5 bg-emerald-400 p-6 shadow-sm rounded-sm flex items-center">
              <svg class="h-8 w-8 fill-white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" width="512" height="512">
                <g id="WA_Logo">
                  <g>
                    <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M20.463,3.488C18.217,1.24,15.231,0.001,12.05,0    C5.495,0,0.16,5.334,0.157,11.892c-0.001,2.096,0.547,4.142,1.588,5.946L0.057,24l6.304-1.654    c1.737,0.948,3.693,1.447,5.683,1.448h0.005c6.554,0,11.89-5.335,11.893-11.893C23.944,8.724,22.708,5.735,20.463,3.488z     M12.05,21.785h-0.004c-1.774,0-3.513-0.477-5.031-1.378l-0.361-0.214l-3.741,0.981l0.999-3.648l-0.235-0.374    c-0.99-1.574-1.512-3.393-1.511-5.26c0.002-5.45,4.437-9.884,9.889-9.884c2.64,0,5.122,1.03,6.988,2.898    c1.866,1.869,2.893,4.352,2.892,6.993C21.932,17.351,17.498,21.785,12.05,21.785z M17.472,14.382    c-0.297-0.149-1.758-0.868-2.031-0.967c-0.272-0.099-0.47-0.149-0.669,0.148s-0.767,0.967-0.941,1.166    c-0.173,0.198-0.347,0.223-0.644,0.074c-0.297-0.149-1.255-0.462-2.39-1.475c-0.883-0.788-1.48-1.761-1.653-2.059    s-0.018-0.458,0.13-0.606c0.134-0.133,0.297-0.347,0.446-0.521C9.87,9.97,9.919,9.846,10.019,9.647    c0.099-0.198,0.05-0.372-0.025-0.521C9.919,8.978,9.325,7.515,9.078,6.92c-0.241-0.58-0.486-0.501-0.669-0.51    C8.236,6.401,8.038,6.4,7.839,6.4c-0.198,0-0.52,0.074-0.792,0.372c-0.272,0.298-1.04,1.017-1.04,2.479    c0,1.463,1.065,2.876,1.213,3.074c0.148,0.198,2.095,3.2,5.076,4.487c0.709,0.306,1.263,0.489,1.694,0.626    c0.712,0.226,1.36,0.194,1.872,0.118c0.571-0.085,1.758-0.719,2.006-1.413c0.248-0.694,0.248-1.29,0.173-1.413    C17.967,14.605,17.769,14.531,17.472,14.382z"/>
                  </g>
                </g>
              </svg>
            </div>
            <div class="flex-1 text-left text-size-md text-gray-500">
              <p class="font-bold">WhatsApp</p>
              <p>+265995887474</p>
            </div>
          </div>


          <div class="flex space-x-2">
            <div class=" text-left mb-1.5 bg-red-400 p-6 shadow-sm rounded-sm flex items-center">
              <!-- <svg class="h-8 w-8 fill-white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17.5,24C9.351,24.229-5.075,9.787,1.862,1.938,1.887,1.914,2.954.984,2.954.984a3.583,3.583,0,0,1,4.908.041l2.152,2.318a3.522,3.522,0,0,1-.039,4.91l-.388.394a11.6,11.6,0,0,0,5.754,5.778l.411-.4a3.585,3.585,0,0,1,4.945,0L22.934,16.1a3.515,3.515,0,0,1,.082,4.946s-.93,1.066-.954,1.091A6.39,6.39,0,0,1,17.5,24ZM3.95,4.1C-.909,10.118,14.644,24.773,19.905,20.05c0,0,.924-1.059.949-1.083a.5.5,0,0,0,0-.707l-2.238-2.074c-.3-.25-.469-.253-.747-.04l-1.136,1.118a1.5,1.5,0,0,1-1.587.332A14.852,14.852,0,0,1,6.414,8.877a1.5,1.5,0,0,1,.323-1.61L7.845,6.141a.485.485,0,0,0,.009-.717L5.7,3.106a.487.487,0,0,0-.667.04C5.009,3.171,3.95,4.1,3.95,4.1ZM16.808,15.086h0ZM24,9.5A9.511,9.511,0,0,0,14.5,0a1.5,1.5,0,0,0,0,3A6.508,6.508,0,0,1,21,9.5a1.5,1.5,0,0,0,3,0Zm-5,0A4.505,4.505,0,0,0,14.5,5a1.5,1.5,0,0,0,0,3A1.5,1.5,0,0,1,16,9.5a1.5,1.5,0,0,0,3,0Z"/></svg> -->
              <svg class="h-8 w-8 fill-white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A12.013,12.013,0,0,0,0,12c-.125,9.574,11.159,15.429,18.9,9.817a1.5,1.5,0,1,0-1.727-2.453C11.42,23.582,2.863,19.146,3,12,3.472.07,20.529.072,21,12v1.5a1.5,1.5,0,0,1-3,0V12C17.748,4.071,6.251,4.072,6,12a6.017,6.017,0,0,0,10.078,4.388A4.5,4.5,0,0,0,24,13.5V12A12.013,12.013,0,0,0,12,0Zm0,15a3,3,0,0,1,0-6A3,3,0,0,1,12,15Z"/></svg>
            </div>
            <div class="flex-1 text-left text-size-md text-gray-500">
              <p class="font-bold">Email</p>
              <p>austinmpira4@gmail.com</p>
            </div>
          </div>


        <!-- </div> -->
       </div>
    </div>
    <!-- just the pattern -->
    <div class=" dots-pattern h-32 mb-32">
    </div>
    <!-- the footer -->
    <Footer/>
  </div>
</template>

<script>
import Animation from './Animation.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';

export default {
  name: 'HelloWorld',
  components: {
    Navbar,
    Animation,
    Footer
  },
  mounted: () => {
    localStorage.setItem('nav-select', 2)
  },
  props: {
    msg: String
  },
  data: () => {
    return {
      products: [
        {
          name: 'Groundnuts',
          image: require('../assets/groundnut-sack.jpeg')
        },
        {
          name: 'Groundnut Cooking Oil',
          image: require('../assets/groundnut-oil.jpeg')
        },
        {
          name: 'Soya',
          image: require('../assets/soya.jpeg')
        },
        {
          name: 'Soya Cooking Oil',
          image: require('../assets/soya-oil.jpeg')
        },
        {
          name: 'Soya Flour',
          image: require('../assets/soya-flour.jpeg')
        },
        {
          name: 'Goat Meat',
          image: require('../assets/goat-meat.jpeg')
        },
        {
          name: 'Goat Milk',
          image: require('../assets/goat-milk.jpeg')
        },
        {
          name: 'Beef',
          image: require('../assets/beef.jpg')
        },
        {
          name: 'Dairy Milk',
          image: require('../assets/milk.jpeg')
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
