<template>
    <div>
        <Contact/>
    </div>
</template>

<script>
import Contact from '@/components/Contact.vue';

export default {
    name: 'contact-view',
    components: {
        Contact
    }
}
</script>