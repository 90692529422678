<template>
  <div class="">
    <!-- the navbar -->
    <Navbar class=" sticky top-0"/>
    <!-- company description -->
    <div class=" bg-blue-400">
      <!-- animation -->
       <Animation/>
      <div class="py-32 space-y-5">
        <p class=" text-center md:text-5xl text-4xl px-4 text-white font-bold">
          Our Agricultural Produce
        </p>
        <p class=" text-center md:text-md text-sm px-2 text-white">
          We have a wide range of agricultural products as shown below.
        </p>
      </div>
    </div>
    <!-- company Focus -->
    <div class="py-6">
      <!-- the focus areas -->
      <div>
        <span class=" font-bold">Our Products</span>
      </div>
      <!-- the cards for the focus areas -->
       <div class="relative grid grid-cols-1 md:grid-cols-5 mb-4 gap-6 mt-6 px-6 bg-50">
        <div v-for="(data, i) in products" :key="i" class="w-full shadow-sm hover:shadow-md border col-span-1 cursor-pointer">
          <div class="">
            <div class=" bg-gray--50 text-left px-3 py-3 justify-between flex">
              <span class=" text-xs font-bold">{{(i + 1)}}. {{ data.name }}</span>
              <span class=" text-xs font-medium text-red-800"></span>
            </div>
            <div class=" text-left mb-1.5">
              <img class=" h-64 w-full object-cover object-center" :src="data.image"/>
            </div>
          </div>
        </div>
       </div>
    </div>
    <!-- just the pattern -->
    <div class=" dots-pattern h-16 mb-32">
    </div>
    <!-- the footer -->
    <Footer/>
  </div>
</template>

<script>
import Animation from './Animation.vue';
import Footer from './Footer.vue';
import Navbar from './Navbar.vue';

export default {
  name: 'HelloWorld',
  components: {
    Navbar,
    Animation,
    Footer
  },
  props: {
    msg: String
  },
  mounted: () => {
    localStorage.setItem('nav-select',  1)
  },
  data: () => {
    return {
      products: [
        {
          name: 'Groundnuts',
          image: require('../assets/groundnut-sack.jpeg')
        },
        {
          name: 'Groundnut Cooking Oil',
          image: require('../assets/groundnut-oil.jpeg')
        },
        {
          name: 'Soya',
          image: require('../assets/soya.jpeg')
        },
        {
          name: 'Soya Cooking Oil',
          image: require('../assets/soya-oil.jpeg')
        },
        {
          name: 'Soya Flour',
          image: require('../assets/soya-flour.jpeg')
        },
        {
          name: 'Goat Meat',
          image: require('../assets/goat-meat.jpeg')
        },
        {
          name: 'Goat Milk',
          image: require('../assets/goat-milk.jpeg')
        },
        {
          name: 'Beef',
          image: require('../assets/beef.jpg')
        },
        {
          name: 'Dairy Milk',
          image: require('../assets/milk.jpeg')
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
