<template>
    <div class=" z-50">
      <!-- the navbar -->
      <div class="bg-white text-left shadow-sm flex justify-between py-4 px-4 border-r-0 border-t-0 border-l-0 border-b">
        <!-- the name of the company -->
        <div @click="navigate(0)" class=" flex space-x-1 items-center cursor-pointer">
          <svg class="h-6 w-6 fill-gray-800 hover:scale-105" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm4,18h-2v-4h-3c-1.654,0-3-1.346-3-3V6h2v5c0,.552,.448,1,1,1h3V6h2v12Z"/></svg>
          <span class="text-title font-bold text-gray-800 cursor-pointer hover:text-gray-700"><sup>th</sup> Fourth General Suppliers</span>
        </div>
        
        <!-- the pages items -->
        <div class="flex items-center text-gray-800 space-x-4">
          <!-- <svg class="h-5 w-5 fill-gray-800" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
            <path d="m8.5,9.5c0,.551.128,1.073.356,1.537-.49.628-.795,1.407-.836,2.256-.941-.988-1.52-2.324-1.52-3.792,0-3.411,3.122-6.107,6.659-5.381,2.082.428,3.769,2.105,4.213,4.184.134.628.159,1.243.091,1.831-.058.498-.495.866-.997.866h-.045c-.592,0-1.008-.527-.943-1.115.044-.395.021-.81-.08-1.233-.298-1.253-1.32-2.268-2.575-2.557-2.286-.525-4.324,1.207-4.324,3.405Zm-3.89-1.295c.274-1.593,1.053-3.045,2.261-4.178,1.529-1.433,3.531-2.141,5.63-2.011,3.953.256,7.044,3.719,6.998,7.865-.019,1.736-1.473,3.118-3.208,3.118h-2.406c-.244-.829-1.002-1.439-1.91-1.439-1.105,0-2,.895-2,2s.895,2,2,2c.538,0,1.025-.215,1.384-.561h2.932c2.819,0,5.168-2.245,5.208-5.063C21.573,4.715,17.651.345,12.63.021c-2.664-.173-5.191.732-7.126,2.548-1.499,1.405-2.496,3.265-2.855,5.266-.109.608.372,1.166.989,1.166.472,0,.893-.329.972-.795Zm7.39,8.795c-3.695,0-6.892,2.292-7.955,5.702-.165.527.13,1.088.657,1.253.526.159,1.087-.131,1.252-.657.351-1.127,1.052-2.089,1.952-2.825l1.401,2.101c.355.532,1.136.532,1.491,0l1.254-1.882,1.254,1.882c.355.532,1.136.532,1.491,0l1.363-2.044c.867.729,1.542,1.67,1.884,2.768.134.428.528.702.955.702.099,0,.198-.015.298-.045.527-.165.821-.726.657-1.253-1.063-3.41-4.26-5.702-7.955-5.702Z"/>
          </svg> -->
          <!-- <svg class="h-5 w-5 fill-gray-800 hover:fill-blue-500" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
            <path d="m20.91,7.713C20.358,3.736,17.057.519,13.06.061c-2.505-.288-4.939.441-6.851,2.05-1.887,1.588-3.051,3.906-3.193,6.36-.016.276.194.513.47.528.276.022.512-.194.528-.47.126-2.182,1.161-4.242,2.839-5.654,1.699-1.429,3.863-2.078,6.093-1.82,3.55.406,6.483,3.264,6.974,6.795.192,1.387.031,2.792-.467,4.063-.488,1.248-1.776,2.087-3.205,2.087h-2.517c.171-.294.269-.636.269-1,0-1.103-.897-2-2-2s-2,.897-2,2,.897,2,2,2h4.248c1.836,0,3.499-1.094,4.137-2.722.56-1.432.741-3.01.525-4.565Zm-9.91,5.287c0-.552.449-1,1-1s1,.448,1,1-.449,1-1,1-1-.448-1-1Zm5.455-1.728c.361-.707.545-1.471.545-2.272,0-2.757-2.243-5-5-5s-5,2.243-5,5c0,1.159.406,2.289,1.143,3.182.176.213.146.528-.067.704-.093.077-.206.114-.318.114-.144,0-.287-.062-.386-.182-.884-1.071-1.372-2.427-1.372-3.818,0-3.309,2.691-6,6-6s6,2.691,6,6c0,.961-.22,1.879-.654,2.728-.127.246-.431.342-.673.218-.246-.126-.344-.428-.218-.673Zm-4.455,5.728c-3.858,0-7.219,2.69-7.99,6.398-.056.271.134.602.49.602.232,0,.44-.162.489-.398.35-1.686,1.314-3.131,2.634-4.131l1.304,1.955c.479.719,1.666.719,2.146,0l.894-1.441.96,1.441c.239.359.641.574,1.073.574s.834-.215,1.073-.574l1.303-1.955c1.32,1,2.284,2.445,2.635,4.131.056.271.326.448.591.388.271-.056.444-.32.388-.591-.771-3.708-4.131-6.398-7.989-6.398Zm2.241,3.871c-.077.116-.194.129-.241.129s-.164-.013-.241-.129l-.961-1.442c-.356-.533-1.239-.534-1.596,0l-.961,1.441c-.078.116-.194.129-.241.129s-.163-.013-.241-.129l-1.299-1.948c1.053-.589,2.265-.923,3.54-.923s2.487.334,3.539.923l-1.298,1.948Z"/>
          </svg> -->
          <span @click="navigate(1)" :class=" selection == 1 ? 'text-sm text-blue-500 font-medium hover:text-blue-400 cursor-pointer' : 'text-sm font-medium hover:text-blue-500 cursor-pointer'">Products</span>
          <span @click="navigate(2)" :class=" selection == 2 ? 'text-sm text-blue-500 font-medium hover:text-blue-400 cursor-pointer' : 'text-sm font-medium hover:text-blue-500 cursor-pointer'">Contact Us</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'navbar-component',
      props: {
      },
      data: () => {
        return {
          selection: localStorage.getItem('nav-select')
        }
      },
      methods: {
        navigate (index) {
          switch (index) {
            case 0:
            localStorage.setItem('nav-select',  0)
              this.$router.push('/')
              break
            case 1:
            localStorage.setItem('nav-select',  1)
              this.$router.push('/products')
              break
            default:
              localStorage.setItem('nav-select',  2)
              this.$router.push('/contact')
          }
        }
      }
    }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  